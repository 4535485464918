import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { FaHome, FaChevronRight } from "react-icons/fa";

const data = [
  {
    id: 1,
    date: "12/04/2024",
    name: "Skima",
    location: "Nigeria",
    import_origin: "United States of America",
    status: "Delivered",
    action: "view",
  },
  {
    id: 2,
    date: "12/04/2024",
    name: "Modiant",
    location: "Cape Verde",
    import_origin: "Sagamou",
    status: "Delivered",
    action: "view",
  },
  {
    id: 3,
    date: "12/04/2024",
    name: "Vera",
    location: "South Sudan",
    import_origin: "Iraq",
    status: "Delivered",
    action: "view",
  },
];

const columns = [
  {
    header: "S/N",
    accessorKey: "id",
  },
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Location",
    accessorKey: "location",
  },
  {
    header: "Import Origin",
    accessorKey: "import_origin",
  },
  {
    header: "Status",
    accessorKey: "status",
  },
  {
    header: "View",
    accessorKey: "view",
  },
];
function ImportsTable() {
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState("");

  const handleFilter = (value) => {
    if (value !== filters) {
      setFilters(value);
    }
  };

  useEffect(() => {
    // This will only run when sorting or filters change
    console.log("Sorting or filters changed:", sorting, filters);
  }, [sorting, filters]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filters,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: (newGlobalFilter) => {
      setFilters(newGlobalFilter);
    },
  });

  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-2 pt-5 mb-5 ">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]">Imports</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col m-6 space-y-6 md:ml-2 mt-[0.3rem] w-1/2 md:w-full">
          {/* Input starts */}
          <input
            type="text"
            className="border-2 rounded-xl w-1/2 py-1 outline-none text-xl placeholder-gray-400 pl-2"
            placeholder="Search"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          {/* Input Ends */}

          <table className="">
            <thead className="bg-[#d2d3d3] h-12 rounded ">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-start pl-3"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "up", desc: "down" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="font-sans pt-3 justify-center border-b-2 text-start pl-3"
                    >
                      {cell.column.id === "view" ? (
                        <Link to="/mobile/importsdetails">
                          <button
                            className="bg-[#049805] text-white w-11 h-8 mb-2 rounded-md font-normal hover:bg-[#216c21]"
                            onClick={() => console.log("Clicked")}
                          >
                            {row.original.action}
                          </button>
                        </Link>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row space-x-3">
            <button onClick={() => table.setPageIndex(0)}>First page</button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              Previous page
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              Next page
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              Last page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportsTable;
