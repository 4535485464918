import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { IoImageOutline } from "react-icons/io5";
import { PiCurrencyNgn } from "react-icons/pi";

const ImportsDetails = () => {
  return (
    <div className="md:mt-[-2rem]">
      <div className="flex  md:ml-[7rem] ml-8 md:my-8 space-x-8 md:space-x-0 font-bold md:font-normal mb-5 md:mb-0 ">
        <Link to="/home/mobile/imports">
          <span className="md:ml-[16rem] ml-5  font-bold text-2xl md:text-4xl cursor-pointer hover:text-gray-400 transition ease-in-out duration-300 ">
            <BsArrowLeft />
          </span>
        </Link>
        <p className="flex text-xl md:text-3xl mt-7 md:mt-8 md:px-[8rem]">
          Import Details
        </p>
      </div>

      <span>
        <button className="w-44 h-9 bg-red-900 hover:bg-red-700 active:bg-red-600 transition ease-in-out duration-300 mt-4 ml-[2rem] md:ms-[68rem] items-end rounded text-sm text-white font-semibold ">
          Change Delivery status
        </button>
      </span>

      <div className="border-b-2 ">
        <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[14rem] mb-5 space-y-6 md:space-y-0">
          <div>
            <h1 className="font-bold text-xl mb-2">Sender Information</h1>
            <div className="space-y-3">
              <p>Name: M & M Interiors</p>
              <p>Country: China</p>
              <p>State: Beijing</p>
              <p>Address: No. 24, Line Yiwu International trade </p>
              <p>Phone Number: 081222345689</p>
              <p>Date of shipment Departure: 3rd January 2024</p>
            </div>
          </div>
          <div className="">
            <h1 className="font-bold text-xl mb-2">Receiver Information</h1>
            <div className="space-y-3">
              <p>Name: Moses Olajibare</p>
              <p>Country: Nigeria</p>
              <p>State: Lagos</p>
              <p>Zip Code: KOA OA4 </p>
              <p>Address: No. 1 Albion Crescent Ottawa</p>
              <p>Phone Number: 081222345689</p>
              <p>Delivery Option: Sea Cargo</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[18.5rem] mb-5 space-y-6 md:space-y-0">
        <div>
          <div>
            <h1 className="font-bold text-xl mb-2">Package Information</h1>
          </div>
          <div className="space-y-3">
            <p>What are you shipping: 5 Bags of rice</p>
            <p>Weight of goods: 50kg</p>
            <p className="flex space-x-1 text-center">
              Picture of goods:{" "}
              <span className="flex space-x-1 ml-1 md:mt-1 cursor-pointer text-gray-600">
                <IoImageOutline /> <FiDownload />
              </span>
              <p>Download</p>
            </p>
            <p> Insurance: Yes</p>
            <p className="flex">
              Value of Package: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> 2,500,000</p>
            </p>
          </div>
        </div>
        <div className="">
          <div>
            <h1 className="font-bold text-xl mb-2 ">Other Information</h1>
          </div>
          <div className="space-y-3">
            <p className="flex">
              Amount of goods: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> 150, 000</p>
            </p>
            <p className="flex">
              Insurance Amount: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> 40, 000</p>
            </p>
            <p className="flex">
              Amount to be paid: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> 350, 000</p>
            </p>
            <p>
              Status of payment: <span className="text-[#049805]">paid</span>
            </p>

            <p>
              Status of Delivery:{" "}
              <button className="w-24 h-8 rounded bg-[#049805] text-white">
                Delivered
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportsDetails;
