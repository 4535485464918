import React, { useState } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const SeaCargoPriceModal = ({ isOpen, handleCloseModal,  }) => {
  const [zones, setZones] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulating asynchronous operation with setTimeout
    setTimeout(() => {
      // Reset loading state
      setIsLoading(false);
      // Close modal
      handleCloseModal();
      // Show success toast message
      toast.success("Zone added successfully!");
    }, 2000); // 2 seconds delay (replace with your actual API call)

    // Reset zone input value
    setZones("");
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center lg:ml-[13rem] bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Add Zone
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Select Kg:
                </label>
                <select
                  name="unit"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={zones}
                  onChange={(e) => setZones(e.target.value)}
                >
                  <option value="">Select Kg</option>
                  <option value="2kg-10kg">2kg---10kg</option>
                  <option value="11kg---20kg">11kg---20kg</option>
                  <option value="21kg---31kg">21kg---31kg</option>
                  <option value="32kg---40kg">32kg---40kg</option>
                  <option value="41kg---50kg">41kg---50kg</option>
                  <option value="51kg---61kg">51kg---61kg</option>
                  <option value="61kg---70kg">61kg---70kg</option>
                  <option value="71kg---80kg">71kg---80kg</option>
                  <option value="82kg---70kg">82kg---70kg</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter Price:
                </label>
                <input
                  type="text"
                  name="zone"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default SeaCargoPriceModal;
