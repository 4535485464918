import React, { useState } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import AirCargoPrice from "./AirCargo/AirCargoPrice";
import SeaCargoPrice from "./SeaCargoPrice/SeaCagoPrice";
import SeaCargoPriceModal from "./SeaCargoPrice/SeaCargoPriceModal";
import AirCargoPriceModal from "./AirCargo/AirCargoPriceModal";
import { ToastContainer } from "react-toastify";

const Cargo = () => {
  const [activeCargo, setActiveCargo] = useState("Air");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddPrice = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="bg-white">
      <div className="mx-auto mt-0 p-4 bg-white">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-2 pt-3 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">Cargo</p>
          </div>
          <button
            className="bg-[#049805] cursor-pointer text-white px-6 py-3 mr-3 rounded w-full md:w-auto"
            onClick={handleAddPrice}
          >
            Add Price
          </button>
        </div>
      </div>

      {/* The Tab to switch between Air cargo and Sea cargo ...STARTS... */}
      <div className="w-1/2 lg:w-[65rem] mx-auto text-center rounded-3xl bg-gray-200">
        <div className="flex justify-between mx-5 items-center h-14 py-4">
          {/* Button for Air Cargo */}
          <p
            className={`cursor-pointer ${
              activeCargo === "Air"
                ? "bg-[#049805] rounded-3xl w-1/2 h-10 py-2 text-white text-center"
                : "bg-gray-200 rounded-3xl w-1/2 h-10 py-2 text-center"
            }`}
            onClick={() => setActiveCargo("Air")}
          >
            Air Cargo
          </p>

          {/* Button for Sea Cargo */}
          <p
            className={`cursor-pointer ${
              activeCargo === "Sea"
                ? "bg-[#049805] rounded-3xl w-1/2 h-10 py-2 text-white text-center"
                : "bg-gray-200 rounded-3xl w-1/2 h-10 py-2 text-center "
            }`}
            onClick={() => setActiveCargo("Sea")}
          >
            Sea Cargo
          </p>
        </div>
      </div>


      {/* The Tab to switch between Air cargo and Sea cargo ...ENDS... */}
      {activeCargo === "Air" ? <AirCargoPrice /> : <SeaCargoPrice />}

      {isModalOpen &&
        // Conditionally render modal based on activeCargo value
        (activeCargo === "Air" ? (
          // Render AirCargoPrice modal
          <AirCargoPriceModal
            isOpen={isModalOpen}
            handleCloseModal={() => setIsModalOpen(false)}
          />
        ) : (
          // Render SeaCargoPrice modal
          <SeaCargoPriceModal
            isOpen={isModalOpen}
            handleCloseModal={() => setIsModalOpen(false)}
          />
        ))}

      <ToastContainer />
    </div>
  );
};

export default Cargo;
