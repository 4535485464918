import React, { useState } from "react";
import SeaCargoPriceTable from "./SeaCargoPriceTable";

const SeaCargoPrice = () => {

  const [zones, setZones] = useState([]);

  
  return (
    <div className="bg-white">
      <div className="mx-auto mt-0 p-4 bg-white "></div>
      {zones.length > 0 ? (
        <SeaCargoPriceTable zones={zones} />
      ) : (
        <p className="text-gray-500 text-center my-20">
          No price has been added yet
        </p>
      )}
    </div>
  );
};

export default SeaCargoPrice;
