import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ViewAllAffiliateBankAcctPage from "./ViewAllAffiliateBankAcctPage";
import ViewAllUnsuccessfulPage from "./ViewAllUnsuccessfulPage";
import ViewAllSuccessfulPage from "./ViewAllSuccessfulPage";
import AdminOfficerPage from "./AdminOfficerPage";
import ViewAllAffilaiteReqPage from "./ViewAllAffilaiteReqPage";
import TotalAffiliateRequestPage from "./TotalAffiliateRequestPage";
import StaffPage from "./StaffPage";
import PendingLeavePage from "./PendingLeavePage";
import LeaveRequestPage from "./LeaveRequestPage";
import ComplainsPage from "./ComplainsPage";
import ViewComplaintPage from "./ViewComplaintPage";
import ViewPendingLeaveRequestPage from "./ViewPendingLeaveRequestPage";
import AirCargoTable from "./components/AirCargoPlacement/AirCargoTable";
import SeaCargoTable from "./components/SeaCargoPlacement/SeaCargoTable";
import MobilePage from "./MobilePage";
import InventoryPage from "./InventoryPage";
import VendorsPage from "./VendorsPage";
import DHLShippingTable from "./components/Mobile/MobileListings/DHLShipping/DHLShippingTable";
import BudgetDeliveryTable from "./components/Mobile/MobileListings/UPSDelivery/UPSDeliveryTable";
import ImportsTable from "./components/Mobile/MobileListings/Imports/ImportsTable";
// import AirCargo from "./components/AirCargoPlacement/Aircargo";
import AirCargo from "./components/Mobile/MobileListings/AirCargo/AirCargoTable";
import SeaCargo from "./components/Mobile/MobileListings/SeaCargo/SeaCargoTable";
import AirCargoDetails from "./components/Mobile/MobileListings/AirCargo/AirCargoDetails";
import BudgetDeliveryDetails from "./components/Mobile/MobileListings/UPSDelivery/UPSDeliveryDetails";
import SeaCargoDetails from "./components/Mobile/MobileListings/SeaCargo/SeaCargoDetails";
import ImportsDetails from "./components/Mobile/MobileListings/Imports/ImportsDetails";
import DHLShippingDetails from "./components/Mobile/MobileListings/DHLShipping/DHLDetails";
import LoginPage from "./LoginPage";
import HomeLayout from "./components/HomeLayout";
import HomePage from "./HomePage";
import CreateShipmentPage from "./CreateShipmentPage";
import CreateShipmentcontsPage from "./CreateShipmentcontsPage";
import ShipmentBatchPage from "./ShipmentBatchPage";
import ScrollToTop from "./ScrollToTop";
import CustomerPage from "./CustomerPage";
import BatchDetailsPage from "./BDetailsPage";
import LocatnPage from "./LocatnPage";
import PendingPaymentOrderPage from "./PendingPaymentOrderPage";
import AircargoPage from "./AircargoPage";
import ViewBatchDetailsPage from "./ViewBatchDetailsPage";
import NotFound from "./404";
import SeaCargoPage from "./SeaCargoPage";
import ConfirmationPage from "./ConfirmationPage";
import ViewItemPage from "./ViewItemsPage";
import EditShipMentOrderPage from "./EditShipMentOrderPage";
import AdminPage from "./AdminPage";
import AffiliatePage from "./AffiliatePage";
import NewsPage from "./NewsPage";
import SeaEditShipmentOrderPage from "./SeaEditShipmentOrderPage";
import SeaViewItemPage from "./SeaViewItemPage";
import ViewAllTotalAffiliatePage from "./ViewAllTotalAffiliatePage";
import PricesPage from "./PricesPage";
import ZonePage from "./ZonePage";
import CountryPage from "./CountryPage";
import DHLPage from "./DHLPage";
import CargoPage from "./CargoPage";
import Scanner from "./ScannerAppPage";
import ScannerItemsPage from "./ScannerItemsPage";
import HistoryPage from "./HistoryPage";
import VendorNewPage from "./VendorNewPage";

const AppRoute = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="home" element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path="create" element={<CreateShipmentPage />} />
          <Route
            path="create/createShipmentContd"
            element={<CreateShipmentcontsPage />}
          />
          <Route path="vendor" element={<VendorNewPage />} />
          <Route
            path="create/createShipmentContd/Confirmation"
            element={<ConfirmationPage />}
          />
          <Route path="shipmentbatch" element={<ShipmentBatchPage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="details" element={<BatchDetailsPage />} />
          <Route
            path="details/viewBatchDetails"
            element={<ViewBatchDetailsPage />}
          />
          <Route path="location" element={<LocatnPage />} />
          <Route
            path="pendingpaymentorder"
            element={<PendingPaymentOrderPage />}
          />
          <Route path="aircargo" element={<AircargoPage />} />
          <Route path="aircargo/table" element={<AirCargoTable />} />
          <Route path="aircargo/viewitem" element={<ViewItemPage />} />
          <Route path="aircargo/edit" element={<EditShipMentOrderPage />} />

          <Route path="sea" element={<SeaCargoPage />} />
          <Route path="seacargo/table" element={<SeaCargoTable />} />
          <Route path="sea/edit" element={<SeaEditShipmentOrderPage />} />
          <Route path="sea/viewitem" element={<SeaViewItemPage />} />

          <Route path="affiliate" element={<AffiliatePage />} />
          <Route
            path="affiliate/totalaffiliate"
            element={<ViewAllTotalAffiliatePage />}
          />
          <Route
            path="affiliate/totalbankdetails"
            element={<ViewAllAffiliateBankAcctPage />}
          />
          <Route
            path="affiliate/totalUnsuccessful"
            element={<ViewAllUnsuccessfulPage />}
          />
          <Route
            path="affiliate/successfulwithdrawals"
            element={<ViewAllSuccessfulPage />}
          />
          <Route
            path="affiliate/affiliaterequest"
            element={<ViewAllAffilaiteReqPage />}
          />
          <Route path="adminofficer" element={<AdminOfficerPage />} />
          <Route
            path="affiliaterequest"
            element={<TotalAffiliateRequestPage />}
          />
          <Route path="staff" element={<StaffPage />} />

          <Route path="leave" element={<LeaveRequestPage />} />
          <Route path="leave/:id" element={<ViewPendingLeaveRequestPage />} />
          <Route path="leave/pending" element={<PendingLeavePage />} />
          <Route path="complains" element={<ComplainsPage />} />
          <Route path="complains/:id" element={<ViewComplaintPage />} />
          <Route
            path="leave/pending/:id"
            element={<ViewPendingLeaveRequestPage />}
          />

          <Route path="mobile" element={<MobilePage />} />
          <Route path="scanner" element={<Scanner />} />
          <Route path="inventory" element={<InventoryPage />} />
          {/* <Route path="vendor" element={<VendorsPage />} /> */}

          <Route path="mobile/expressshipping" element={<DHLShippingTable />} />
          <Route
            path="mobile/expressshippingdetails"
            element={<DHLShippingDetails />}
          />

          <Route
            path="mobile/budgetdelivery"
            element={<BudgetDeliveryTable />}
          />
          <Route
            path="mobile/budgetdeliverydetails"
            element={<BudgetDeliveryDetails />}
          />

          <Route path="mobile/aircargo" element={<AirCargo />} />
          <Route path="mobile/aircargodetails" element={<AirCargoDetails />} />

          <Route path="mobile/seacargo" element={<SeaCargo />} />
          <Route path="mobile/seacargodetails" element={<SeaCargoDetails />} />

          <Route path="mobile/imports" element={<ImportsTable />} />

          <Route path="scanner/scanned-item" element={<ScannerItemsPage />} />
          <Route path="scanner/history" element={<HistoryPage />} />

          <Route path="news" element={<NewsPage />} />

          <Route path="admin" element={<AdminPage />} />
          <Route path="admin" element={<AdminPage />} />

          <Route path="prices" element={<PricesPage />} />
          <Route path="zone" element={<ZonePage />} />
          <Route path="country" element={<CountryPage />} />
          <Route path="dhl" element={<DHLPage />} />
          <Route path="cargo" element={<CargoPage />} />
        </Route>

        <Route path="mobile/importsdetails" element={<ImportsDetails />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoute;
